import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
} from '@mui/material';
import { ContentTileComponent } from '../../Components/ContentTitle';
import { Data, Order } from '../../core/interfaces/usage.interface';
import useGentian from '../../core/hookies/gentian.hook';
import { useUserPermissions } from '../../core/hookies/permission.hook';

const MyDashboard = () => {
  const { getUsage } = useGentian();
  const { permissions } = useUserPermissions(); // Use the useUserPermissions hook to get permissions

  const [order, setOrder] = useState<Order>({ by: 'orderDate', dir: 'desc' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const start = page * rowsPerPage; // Calculate start based on current page and rowsPerPage
  const limit = rowsPerPage; // Limit is always equal to rowsPerPage

  const {
    data: usageDataRaw,
    isLoading,
    isError,
  } = getUsage({
    start: 0,
    limit: 100,
    sort: order.by,
    sortDirection: order.dir,
  });

  useEffect(() => {
    console.log('Start:', start);
    console.log('Limit:', limit);
    console.log('Raw usage data:', usageDataRaw);
  }, [start, limit, usageDataRaw]);

  // Ensure usageData is an array
  const usageData = Array.isArray(usageDataRaw?.items) ? usageDataRaw.items : [];

  useEffect(() => {
    if (usageData) {
      console.log('Usage data fetched successfully:', usageData);
      console.log('page', page);
    }
  }, [usageData]);

  const handleRequestSort = (property: keyof Data) => {
    const isAsc = order.by === property && order.dir === 'asc';
    setOrder({ by: property, dir: isAsc ? 'desc' : 'asc' });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when rows per page changes
  };

  const createSortHandler = (property: keyof Data) => () => {
    handleRequestSort(property);
  };

  // const calculateTokenCost = (totalArea: number): number => {
  //   if (totalArea <= 0) return 0;
  //   if (totalArea <= 1) return 25;
  //   if (totalArea <= 30) return 25 + (totalArea - 1) * 12;
  //   return 25 + 29 * 12 + (totalArea - 30) * 6;
  // };

  // const calculateCost = (totalArea: number): number => {
  //   if (totalArea <= 0) return 0;
  //   if (totalArea <= 1) return 30;
  //   if (totalArea <= 30) return 25 + (totalArea - 1) * 15;
  //   return 30 + 29 * 15 + (totalArea - 30) * 15;
  // };

  const calculateHestiCost = (totalArea: number): number => {
    if (totalArea <= 0) return 0;
    if (totalArea <= 1) return 8;
    return 8 * totalArea;
  };
  const rows: Data[] =
    usageData.map((item, index) => ({
      id: index,
      product: item.product,
      clientName: item.clientName,
      orderDate: item.orderDate,
      totalArea: item.totalArea,
      biodiversityUnits: item.biodiversityUnits,
      status: item.status,
      projectName: item.projectName ? item.projectName : item.uuid,
      cost: calculateHestiCost(item.totalArea), // Calculate cost using the new formula
    })) || [];

  const sortedRows = rows.sort((a, b) => {
    if (order.dir === 'asc') {
      return a[order.by] < b[order.by] ? -1 : 1;
    } else {
      return a[order.by] > b[order.by] ? -1 : 1;
    }
  });

  const paginatedRows = sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Alert severity="error">Error fetching usage data</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: '20px', display: 'flex', flexDirection: 'column' }}>
      <ContentTileComponent className="hide-on-fullscreen">
        <Box sx={{ pl: 'var(--content-spacing-narrow)' }}>
          {permissions.includes('ADMIN:usage') ? 'Admin Dashboard' : 'My Dashboard'}
        </Box>
      </ContentTileComponent>
      <TableContainer component={Paper} sx={{ flexGrow: 1 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={order.by === 'id'}
                  direction={order.by === 'id' ? order.dir : 'asc'}
                  onClick={createSortHandler('id')}
                >
                  ID
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={order.by === 'product'}
                  direction={order.by === 'product' ? order.dir : 'asc'}
                  onClick={createSortHandler('product')}
                >
                  Product
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={order.by === 'product'}
                  direction={order.by === 'product' ? order.dir : 'asc'}
                  onClick={createSortHandler('product')}
                >
                  Project Name/UUID
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={order.by === 'clientName'}
                  direction={order.by === 'clientName' ? order.dir : 'asc'}
                  onClick={createSortHandler('clientName')}
                >
                  Client Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={order.by === 'orderDate'}
                  direction={order.by === 'orderDate' ? order.dir : 'asc'}
                  onClick={createSortHandler('orderDate')}
                >
                  Order Date
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={order.by === 'totalArea'}
                  direction={order.by === 'totalArea' ? order.dir : 'asc'}
                  onClick={createSortHandler('totalArea')}
                >
                  Total Area (ha)
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={order.by === 'biodiversityUnits'}
                  direction={order.by === 'biodiversityUnits' ? order.dir : 'asc'}
                  onClick={createSortHandler('biodiversityUnits')}
                >
                  Biodiversity Units
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={order.by === 'status'}
                  direction={order.by === 'status' ? order.dir : 'asc'}
                  onClick={createSortHandler('status')}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={order.by === 'cost'}
                  direction={order.by === 'cost' ? order.dir : 'asc'}
                  onClick={createSortHandler('cost')}
                >
                  Cost (£)
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.map((row) => (
              <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell>{row.product}</TableCell>
                <TableCell>{row.projectName}</TableCell>
                <TableCell>{row.clientName}</TableCell>
                <TableCell>
                  {new Date(
                    row.orderDate._seconds * 1000 + row.orderDate._nanoseconds / 1e6,
                  ).toLocaleString()}
                </TableCell>
                <TableCell>{row.totalArea}</TableCell>
                <TableCell>{row.biodiversityUnits}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>£ {row.cost.toFixed(2)}</TableCell> {/* Display cost with 2 decimal places */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        component="div"
        count={usageDataRaw?.totalCount - 1 || 0} // Total number of items
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ mt: 2 }}
      />
    </Box>
  );
};

export default MyDashboard;
