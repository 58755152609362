import React from 'react';
import { Menu, MenuItem, Divider, ListItemIcon, Link, Typography } from '@mui/material';
import { Settings, Logout, AccountCircle, SpaceDashboard } from '@mui/icons-material';

export enum MenuClickTarget {
  PROFILE = 'PROFILE',
  SETTINGS = 'SETTINGS',
  LOGOUT = 'LOGOUT',
}

export interface AvatarMenuProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  onMenuClick: (target: MenuClickTarget) => void;
  onClose: () => void;
}

export const AvatarMenuComponent: React.FC<AvatarMenuProps> = ({ anchorEl, onMenuClick, onClose, open }) => {
  const handleClose = () => {
    onClose();
  };

  const handleMenuClick = (target: MenuClickTarget) => {
    onMenuClick(target);
    handleClose();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {/* <MenuItem onClick={() => handleMenuClick(MenuClickTarget.PROFILE)}>
        <ListItemIcon>
          <AccountCircle fontSize="small" />
        </ListItemIcon>
        Profile
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => handleMenuClick(MenuClickTarget.SETTINGS)}>
        <ListItemIcon>
          <Settings fontSize="small" />
        </ListItemIcon>
        Settings
      </MenuItem> */}
      <MenuItem>
        <ListItemIcon>
          <SpaceDashboard fontSize="small" />
        </ListItemIcon>
        <Link href="/mydashboard" underline="none" color="inherit">
          <Typography variant="body2" color="text.primary">
            My Dashboard
          </Typography>
        </Link>
      </MenuItem>
      <MenuItem onClick={() => handleMenuClick(MenuClickTarget.LOGOUT)}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );
};
