// src/core/hooks/useUserPermissions.ts
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { jwtDecode } from 'jwt-decode';

interface DecodedToken {
  permissions: string[];
}

export const useUserPermissions = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [permissions, setPermissions] = useState<string[]>([]);

  useEffect(() => {
    const getUserRoles = async () => {
      try {
        const token = await getAccessTokenSilently();
        const decodedToken = jwtDecode<DecodedToken>(token);
        setPermissions(decodedToken.permissions);
      } catch (error) {
        console.error('Error fetching user permissions:', error);
      }
    };

    getUserRoles();
  }, [getAccessTokenSilently]);

  return { permissions };
};
